import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FcBinoculars, FcInTransit } from "react-icons/fc";
import { MdDoneOutline } from "react-icons/md";
import { RiSearchLine } from 'react-icons/ri';
import { useNavigate } from "react-router-dom";
import { handleGenYear } from "../../utils/gen-year";
import publicRequest, { makeModelRequest } from "../../utils/requestMethods";
import requestUtils from "../../utils/requestUtils";


import CarCarousel from "../../components/text-carousel/CarCarousel";
import HomeCarousel from "../../components/text-carousel/homeCarosel";
import SuggestCarousel from "../../components/text-carousel/HomeSuggest";



const Home = ({ initialData }) => {

  const inputsData = {
    make: "all-makes",
    model: "all-models",
    generation: "all-years",

  };
  const navigate = useNavigate();

  const [searchCheck, setSearchCheck] = useState("");
  const [searchCheckResult, setSearchCheckResult] = useState("");
  const [selectValue, setSelectValue] = useState(inputsData);

  const [suggestdata, setSuggestData] = useState(initialData);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  const [loading, setLoading] = useState(true);

  const PA = process.env.REACT_APP_ASSETS_ADMIN;
  const SA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;

  useEffect(() => {
    async function getsuggest() {
      try {
        const response = await publicRequest.get(`/random/product/auc`);
        setSuggestData(response.data)
        setLoading(false)
      } catch (err) {
        console.clear(err)
      }
    }
    getsuggest()
  }, [])

  useEffect(() => {
    async function getMakes() {
      try {
        const response = await makeModelRequest.get('/make-models')
        setMakes(response?.data)
      } catch (err) {
        console.log(err)
      }
    }
    getMakes();
  }, []);

  useEffect(() => {
    async function getModels() {
      try {
        const response = await makeModelRequest.get(`/make-models?make=${selectValue?.make}`);
        setModels(response.data);

      } catch (err) {
        console.log(err)
      }
    }
    if (selectValue?.make) {
      getModels();
    }
  }, [selectValue?.make])



  useEffect(() => {

    if (searchCheckResult?.vin) {

      navigate(`/vin-decoder/${searchCheckResult?.makes?.toLowerCase()}/${searchCheckResult?.model?.toLowerCase()}/${searchCheckResult?.car_age}/${searchCheckResult?.vin}`);
      window.location.reload();
    }
  }, [searchCheckResult]);



  const selectInput = (e) => {
    setSelectValue(val => {
      return { ...val, [e.target.name]: e.target.value }
    })
  }




  const handleCheckVinByVin = async () => {
    if (searchCheck) {
      try {
        await requestUtils._getProductByVin(searchCheck).then((data) =>
          setSearchCheckResult(data)
        );
      } catch (err) {

      }
    }
  };


  const handleRouterFilteredProducts = () => {
    if (selectValue.make == "all-makes" && selectValue.model == "all-models" && selectValue.generation == "all-years") {
      navigate("/vin-decoder/all-makes/all-models/all-years");
      window.location.reload();
    } else if (selectValue.make && selectValue.model == "all-models" && selectValue.generation == "all-years") {
      navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/all-models/all-years`);
      window.location.reload();
    } else if (selectValue.make && selectValue.model && selectValue.generation == "all-years") {
      navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/${selectValue.model?.toLowerCase()}/all-years`);
      window.location.reload();
    } else if (selectValue.make && selectValue.model && selectValue.generation) {
      navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/${selectValue.model?.toLowerCase()}/${selectValue.generation}`);
      window.location.reload();
    }
  }



  const ltdJson = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "vehicleread",
    "url": "https://vehicleread.com",
    "logo": "https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png",
    "contactPoint": [{
      "@type": "ContactPoint",
      "email": "vehicleread@gmail.com",
      "contactType": "customer service",
      "availableLanguage": ["English"]
    }]
  }

  return (
    <div className=" overflow-hidden " suppressHydrationWarning={true}>

      <Helmet>
        <title>
          vehicleread.com - Auction History | Vehicle History Is Important For
          You
        </title>

        <meta name="description" content="Explore Car History - VIN Lookup, Auction Results, and Vehicle Details | Vehicleread" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://vehicleread.com" />
        <meta property="og:title" content="Auction history - History Of Car    | vehicleread" />
        <meta property="og:description" content="Explore Car History - VIN Lookup, Auction Results, and Vehicle Details" />
        <meta property="og:image" content="https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://vehicleread.com" />
        <meta property="twitter:title" content="Auction history - History Of Car   | vehicleread" />
        <meta property="twitter:description"
          content="Explore Car History - VIN Lookup, Auction Results, and Vehicle Details" />
        <link rel="canonical" href={`https://vehicleread.com`} />

        <meta property="twitter:image" content="https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png" />
        <script type="application/ld+json">
          {JSON.stringify(ltdJson)}
        </script>

      </Helmet>
      <div className="flex w-full justify-center  pt-[5em]  z-55 bg-green-300 items-center gap-5 p-2 2xl:pt-3">
        <span className="2xl:flex items-center gap-2 text-white bg-white rounded-md p-1 hidden  ">
          <span className=" text-green-600 font-bold   mx-auto">Vehicle</span>
          <span className="mx-auto text-green-300 ">Read</span>
          <img className="  w-[20px] 2xl:w-[30px] rounded-md" src={'https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/web-3logo.png'} width="40px" height="80px" alt="vehicleread_main_logo" />
        </span>
        <div className="relative w-[80%]  2xl:w-1/3">
          <input
            name="vinsearch"
            label="Vin Search"
            onChange={(e) => setSearchCheck(e.target.value)}
            className="w-full p-2 rounded-md border-b text-[14px] border-gray-500 focus:outline-none  pl-10"
            placeholder="Enter 17 digit VIN number, Lot"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <RiSearchLine className="text-gray-500" />
          </div>
          <button
            className="whitespace-nowrap absolute w-[35%]  md:w-[25%] text-[16px] h-full right-0  rounded-md text-white bg-green-400"
            onClick={handleCheckVinByVin}
          >
            Check VIN
          </button>
        </div>
      </div>
      {searchCheckResult?.response?.status === 403 && (
        <h3 className="text-[#d43a42] mx-auto bg-gray-800 w-full flex justify-center p-5 text-base whitespace-nowrap">
          Vin Not Found!
        </h3>
      )}
      <div style={{
        backgroundImage: `url(https://vinhistory.s3.amazonaws.com/public/admin/test.jpg)`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        width: '100%',
        overflow: 'hidden',
      }} className="w-full  flex flex-col black-rgba  bg-gradient-to-b from-[#f6f8ff] to-[# f1f4ff]  2xl:flex 2xl:flex-row  2xl:justify-center 2xl:items-center  justify-center items-center p-10 gap-10  2xl:h-[80vh]" >


        <div className="blog-for-seo-right 2xl:h-[500px] max-w-[100em] mx-auto p-6 bg-black bg-opacity-80 shadow-md rounded-lg  flex flex-col items-center justify-center   2xl:flex-row gap-5">
          <div className="w-full">
            <h1 className="text-white text-3xl font-bold  mb-6">Find All VIN Codes and Vehicle History Reports</h1>
            <span className="text-white">
              Whether you are a car buyer or seller you can use vehicleread website to find all important information and data about used cars and vehicles.
              You can see the past history of the car together with its damage and maintenance records, repairs, lot prices, etc. All cars come with detailed description and historical records about the past accidents.
              Also, you can find images that reflect damages on any parts of the car.
              vehicleread is your best source for understanding the chronicle of any vehicle and make an informed decision whether to buy it or not.
              You can also compare cars based on different criteria, especially when purchasing an old vehicle. For car buyers, vehicleread is a perfect solution to decide on the price and value of the car - you can see how much information is available on a particular model or a VIN code and calculate the value based on that.
            </span>

          </div>

          <div className="form-search-main   container  bg-gray-900 ">
            <div className="search-wrapper  p-10 2xl:p-12">
              <form className="new-search-psa  flex flex-col gap-y-5 2xl:gap-y-10 " action="&">
                <div className="flex justify-between items-center">
                  <FcInTransit size="40" className=" w-[30%] border-b  text-green-500 border-green-900 mr-2" />
                  <div className="flex items-center gap-2">
                    <MdDoneOutline className="text-green-500" />
                    <span className="text-white bold">Archived</span>
                  </div>
                </div>
                <div className="flex flex-col w-full 2xl:flex-row gap-10 2xl:items-center">
                  <label className="form-cols 2xl:w-[50%]">
                    <select
                      name="make"
                      id="make"
                      className="mt-1 p-2 w-full text-white border-b bg-gray-900 border-green-700 focus:outline-none focus:ring focus:border-gray-900"
                      onChange={(e) => selectInput(e)}
                    >
                      <option value="all-makes">All makes</option>
                      {
                        makes?.map((value, index) => (
                          <option key={value?.id} value={value?.make}>{value?.make}</option>
                        ))
                      }

                    </select>
                  </label>
                  <label className="form-cols 2xl:w-[50%]">
                    <select
                      name="model"
                      id="model"
                      className="mt-1 p-2 w-full text-white border-b bg-gray-900 border-green-700 focus:outline-none focus:ring focus:border-blue-300"
                      onChange={(e) => selectInput(e)}
                    >
                      <option disabled={makes?.length === 0 ? true : false} value="all-models">All models</option>
                      {
                        models?.map((value, index) => (
                          <option key={value?.id} value={value?.model}>{value?.model}</option>
                        ))
                      }
                    </select>
                  </label>
                </div>
                <div className="w-full">
                  <label className="form-cols mx-auto w-[50%] 2xl:mx-0">
                    <select
                      name="generation"
                      id="generation"
                      className="mt-1 p-2 text-white w-full bg-gray-900 border-b border-green-500 focus:outline-none focus:ring focus:border-blue-300"
                      onChange={(e) => selectInput(e)}
                    >
                      <option value="all-years">All generations</option>
                      {models.length === 0 ? null : handleGenYear(2013, 2025)}
                    </select>
                  </label>
                </div>
                <div className="footer-buttons" style={{ width: '100%' }}>
                  <div className=" flex w-full justify-end  p-3">
                    <button
                      id="show-btn"
                      className="rounded-md bg-black p-3 text-white flex gap-2 items-center"
                      onClick={handleRouterFilteredProducts}
                    >
                      Show vehicles
                      <FcBinoculars size={20} />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className=" w-full ">
            <p className="text-white text-3xl font-bold  mb-6"></p>
            <span className="text-white">
              It is a common sense that one must know car’s vehicle history before they decide to make a purchase especially online.
              These records reveal past accidents, repairs, and potential safety issues, which in turn helps you assess the car’s reliability and safety.
              More than that, vehicleread provides insights into the car’s maintenance history and previous ownership.
              This gives you an opportunity to uncover any potential hidden problems the car may have.
              Equipped with all above-mentioned knowledge you can negotiate on a fair price with the seller or avoid buying a car with problematic past altogether.
            </span>
          </div>

        </div>


      </div>
      <div className="homeContent-Container bg-gradient-to-b from-[#f6f8ff] to-[#cce5fa] p-2 w-full flex gap-[40px] flex-col  justify-center items-center  ">
        <HomeCarousel />


        <SuggestCarousel carData={suggestdata} />

        <CarCarousel name={"bmw"} />
        <CarCarousel name={"audi"} />
        <CarCarousel name={"Mercedes-Benz"} />
        <CarCarousel name={"Toyota"} />
        <CarCarousel name={"Lexus"} />

        {/* <amp-ad width="100vw" height="320"
          type="adsense"
          data-ad-client="ca-pub-3310346880307104"
          data-ad-slot="4796195672"
          data-auto-format="rspv"
          data-full-width="">
          <div overflow=""></div>
        </amp-ad> */}
      </div>
    </div>
  );
};

export default Home;
