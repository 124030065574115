import axios from 'axios';
 
const BASEURL = `https://auctioncar.onrender.com/api/public`;
const MAKEMODELURL = `https://auctioncar.onrender.com/en`;
 const publicRequest =  axios.create({
    baseURL:BASEURL
})

export const  makeModelRequest = axios.create({
    baseURL: MAKEMODELURL
})

export default publicRequest