import React from 'react'

export const custom = {
    span: (label, text) => {
        return (
            <div className="pt-2 p-3 m-1  flex justify-between   ">
                <span>{label}</span>
                <span>{text ? text : "-"}</span>
            </div>
        )
    }
}

export const customAdd = {

    span: (label, text) => {
        return (
            <div className=" flex justify-between">
                <span className="flex gap-3 align-items">{label}</span>
                <span>{text ? text : "-"}</span>
            </div>
        )


    }
}


